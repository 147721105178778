
var openings = {
  "festivals": [
    {
      "name": "Neujahr",
      "day": 1,
      "month": 1
    },
    {
      "name": "Karfreitag",
      "day": 29,
      "month": 3
    },
    {
      "name": "Ostermontag",
      "day": 1,
      "month": 4
    },
    {
      "name": "Tag der Arbeit",
      "day": 1,
      "month": 5
    },
    {
      "name": "Christi Himmelfahrt",
      "day": 9,
      "month": 5
    },
    {
      "name": "Brückentag",
      "day": 10,
      "month": 5
    },
    {
      "name": "Pfingstmontag",
      "day": 20,
      "month": 5
    },
    {
      "name": "Fronleichnam",
      "day": 30,
      "month": 5
    },
    {
      "name": "Tag der Deutschen Einheit",
      "day": 3,
      "month": 10
    },
    {
      "name": "Allerheiligen",
      "day": 1,
      "month": 11
    },
    {
      "name": "Heiligabend",
      "day": 24,
      "month": 12
    },
    {
      "name": "1. Weihnachtstag",
      "day": 25,
      "month": 12
    },
    {
      "name": "2. Weihnachtstag",
      "day": 26,
      "month": 12
    },
    {
      "name": "Sylvester",
      "day": 31,
      "month": 12
    }
  ],
  "trainings": [
    {
      "day": 26,
      "month": 3
    },
  ],
  "closings": [
    {
      "monat": "Januar",
      "month": 1,
      "days": [2, 3],
    },
    {
      "monat": "Mai",
      "month": 5,
      "days": [27, 28, 29, 30, 31],
    },
    {
      "monat": "Juni",
      "month": 6,
      "days": [1],
    },
    {
      "monat": "August",
      "month": 8,
      "days": [19, 20, 21, 22, 23],
    },
    {
      "monat": "Oktober",
      "month": 10,
      "days": [4, 30, 31],
    },
    {
      "monat": "Dezember",
      "month": 12,
      "days": [23, 27, 30, 31],
    }
  ],
  "times": [
    {
      "day": "Mon",
      "dayname": "Montag",
      "start": "08:30",
      "end": "15:00"
    },
    {
      "day": "Tue",
      "dayname": "Dientstag",
      "start": "08:30",
      "end": "18:00"
    },
    {
      "day": "Wed",
      "dayname": "Mittwoch",
      "start": "08:30",
      "end": "13:00"
    },
    {
      "day": "Thu",
      "dayname": "Donnerstag",
      "start": "08:30",
      "end": "15:00"
    },
    {
      "day": "Fry",
      "dayname": "Freitag",
      "start": "08:30",
      "end": "13:00"
    }
  ]
};

const date = new Date();
const dayDate = date.getDate();
const day = date.getDay();
const month = date.getMonth() + 1;

let workDay = "";
let workStart = 0;
let workEnd = 0;
let open = true;
let openText = "Heute nicht geöffnet";

//console.log("Aktuelles Datum", date, dayDate, month, day);
function setOpening() {
  openings.times.forEach(entryTime => {
    let daySpan = document.getElementById('day' + entryTime.day);
    if (entryTime.day === getWeekDay(day)) {
      workDay = entryTime.day;
      workStart = entryTime.start;
      workEnd = entryTime.end;
      if (daySpan) {
        daySpan.classList.add("active");
        daySpan.closest('dd').previousSibling.classList.add("active");
        daySpan.innerText = workStart + ' bis ' + workEnd + ' Uhr';
      }
    } else if (daySpan) {
      daySpan.classList.remove("active");
      daySpan.closest('dd').previousSibling.classList.remove("active");
      daySpan.innerText = entryTime.start + ' bis ' + entryTime.end + ' Uhr';
    }
  });

  if (workDay === "" || workDay === "Sat" || workDay === "Sun") {
    open = false;
  } else {
    open = true;
  }

  //console.log("Arbeitszeit", workDay, workStart, workEnd);

  openings.festivals.forEach(entryFestival => {
    if (open) {
      if (entryFestival.day === dayDate && entryFestival.month === month) {
        open = false;
        openText = entryFestival.name +' - geschlossen';
      } else {
        open = true;
      }
    }
  });

  openings.trainings.forEach(entryTraining => {
    if (open) {
      if (entryTraining.day === dayDate && entryTraining.month === month) {
        open = false;
        openText = '<a href="#closingInfo">Fortbildung - heute geschlossen</a>';
      } else {
        open = true;
      }
    }
  });

  openings.closings.forEach(entryClosday => {
    let entryMonth = entryClosday.month;
    entryClosday.days.forEach(entryDay => {
      if (entryDay === dayDate && entryMonth === month) {
        open = false;
        openText = '<a href="#closingInfo">Praxis geschlossen</a>';
      }
    });
  });
}

function getWeekDay(day) {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fry', 'Sat'];
  return days[day];
}

setOpening();

if (open) {
  openText = 'Heute geöffnet <div class="nobr">von ' + workStart + ' bis ' + workEnd + ' Uhr </div>';
}

document.getElementById('openingInfo').innerHTML = openText;

// the Flyout
const openHoursTrigger = document.getElementById('openHoursTrigger');
const flyout = 'openFlyout'

function setOpenHoursFlyout() {

  openHoursTrigger.addEventListener('click', function (event) {
    event.stopPropagation();
    openHoursTrigger.classList.toggle(flyout);
  });

  document.addEventListener('click', function (event) {
    if(window.screen.width > 768) {
      openHoursTrigger.classList.remove(flyout);
    }
  });
  
}


if (window.location.href.indexOf('#oeffnungszeiten') !== -1) {
  openHoursTrigger.classList.toggle(flyout);
}

setOpenHoursFlyout();


